import styled from "styled-components/macro"
import { fontWeight, layout, pxToRem } from "../../../utils/style-utils"

const TableHeader = styled.h4`
  font-size: ${pxToRem(18)};
  padding: 0 ${pxToRem(layout.pageGutterW)};
  height: ${pxToRem(64)};
  color: #575f66;
  font-weight: ${fontWeight.semiBold};
  align-items: center;
  display: flex;
  background: #fff;
`

export default TableHeader
