import styled from "styled-components/macro"
import { FlexCol, FlexColGrow, FlexRow } from "../../../components/FlexComponents"
import TableHeader from "../../../shared/atoms/Table/TableHeader"
import { breakpoints, fontWeight, layout, palette, pxToRem, zIndexes } from "../../../utils/style-utils"
import { HorizontalOverflowContainer } from "../../../components/OverflowContainer"
import { fontFamily } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"

const stickyTableColumnW = 280
const stickyTableColumnWSm = 180
export const stickyTableColumnH = 54
export const leaderboardBreakpointW2 = 750
export const leaderboardBreakpointW = layout.wrapperWidth
export const leaderboardBreakpointWpx = pxToRem(leaderboardBreakpointW)

export interface IHighlightable {
  highlight: boolean
}
// NOTE qac: padding bottom give room for windows scrollback, which sucks with overflow x
export const StickyTableWrapper = styled(FlexRow)`
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    padding-left: ${pxToRem(layout.pageGutterW)};
    padding-left: var(--grid-margin);
  }
  @media (min-width: ${pxToRem(layout.pageContentMaxW)}) {
    padding-right: ${pxToRem(layout.pageGutterW)};
    padding-right: var(--grid-margin);
  }
`

export const StickyTableHeader = styled(TableHeader)`
  border: ${pxToRem(1)} solid ${palette.gray3};
  border-bottom: none;

  & > * > * {
    border-top: none;
  }
  @media (min-width: ${pxToRem(breakpoints.handheld)}) {
    margin-left: ${pxToRem(layout.pageGutterW)};
    margin-left: var(--grid-margin);
  }
  @media (min-width: ${pxToRem(layout.pageContentMaxW)}) {
    margin-right: ${pxToRem(layout.pageGutterW)};
    margin-right: var(--grid-margin);
  }
`

export const TruncateText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const StickyTableColumn = styled(FlexCol)`
  flex-flow: column;
  z-index: ${zIndexes.ui1};
  overflow: visible;
  border: ${pxToRem(1)} solid ${palette.gray3};
  border-right: none;
  flex: 0 1 ${pxToRem(stickyTableColumnW)};
  & > * {
    max-width: 100%;
  }
  @media (max-width: ${leaderboardBreakpointWpx}) {
    box-shadow: 7px 0 7px 0px #3333334a;
    min-width: ${pxToRem(120)};
    max-width: ${pxToRem(stickyTableColumnWSm)};
    flex: 0 1 30vw;
  }
  @media (max-width: ${pxToRem(leaderboardBreakpointW2)}) {
    margin-left: 0;
  }
`
export const StickyTableXWrapper = styled(HorizontalOverflowContainer)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  justify-content: flex-start;

  overflow-y: visible;
  border: ${pxToRem(1)} solid ${palette.poolPageBorder};
  border-left: none;
  border-right: none;
`
export const StickyTableTable = styled.div`
  flex: none;
  min-width: 100%;
  border-right: ${pxToRem(1)} solid ${palette.poolPageBorder};
  &.even-spacing > * {
    justify-content: space-evenly;
  }
  & .spacer {
    flex: 1 1 100%;
    order: -2;
    max-width: ${pxToRem(120)};
  }
`
export const TableRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: ${pxToRem(stickyTableColumnH)};
  border-bottom: ${pxToRem(1)} solid ${palette.gray3};
  color: ${palette.black};
  min-width: 100%;
  width: fit-content;
  font-size: ${pxToRem(14)};
  position: relative;
  overflow: hidden;
  &:not(.no-background--true) {
    background-color: ${palette.white};
  }

  &[href],
  & [href] {
    & .hover__x {
      transition: all 0.2s ease-out;
    }
    @media (hover: hover) {
      &:hover:not(:active) {
        & .hover__x {
          color: ${palette.blue2};
          transform: translateX(0.6em);
        }
      }
    }
    &:active {
      & .hover__x {
        color: ${palette.blue2};
        transform: translateX(0.4em);
      }
    }
  }
  &.overflow-visible {
    overflow: visible;
  }
  @media (min-width: ${leaderboardBreakpointWpx}) {
    & .cta {
      left: 5.9em;
      bottom: 1.4em;
    }
    & .cta span {
      display: none;
    }
  }
  & .cta {
    position: absolute;
    height: 1em;
    color: ${palette.blue2};
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
  }
  & .cta span {
    font-size: 0.8em;
  }
  & .cta svg {
    height: 1em;
    width: 1em;
  }
  & > .padded {
    padding: ${pxToRem(8)} ${pxToRem(16)};
  }
  @media (max-width: ${leaderboardBreakpointWpx}) {
    & .cta {
      right: 0.3em;
      bottom: 0.3em;
    }
    & .mobile__rank {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      display: block;
      padding-top: ${pxToRem(34)};
      padding-left: ${pxToRem(9)};
      color: ${palette.uiSubText4};
      font-size: ${pxToRem(12)};
      font-weight: ${fontWeight.semiBold};
    }
    & .mobile__name {
      padding-left: ${pxToRem(12)};
      padding-bottom: ${pxToRem(8)};
      font-size: ${pxToRem(14)};
    }
  }
  @media (max-width: ${pxToRem(450)}) {
    & .mobile__rank {
      font-size: ${pxToRem(11)};
    }
    & .mobile__name {
      font-size: ${pxToRem(12)};
    }
  }
`
export const TableHeaderRow = styled(TableRow)`
  font-weight: ${fontWeight.semiBold};
  font-family: ${fontFamily.condensed};
  font-size: ${pxToRem(11)};
  letter-spacing: 0.1em;
  background-color: ${palette.gray97};
  color: ${palette.gray50};
  border-bottom: none;
  text-transform: uppercase;
`
export const Col = styled.div`
  flex: 0 0 ${pxToRem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StandingsPlayerNameCol = styled(FlexColGrow)`
  font-weight: ${fontWeight.semiBold};
`
export const StandingsPlayerRankCol = styled(FlexCol)<IHighlightable>`
  height: 100%;
  color: #000;
  font-weight: ${fontWeight.bold};
  min-width: ${pxToRem(90)};
  padding-right: ${pxToRem(4)};
  margin-right: ${pxToRem(22)};
  justify-content: center;
  border-left: ${pxToRem(4)} solid ${({ highlight }) => (highlight ? `${palette.uiNavBlue1}` : `rgba(0,0,0,0)`)};
  @media (max-width: ${leaderboardBreakpointWpx}) {
    justify-content: flex-end;
    min-width: ${pxToRem(50)};
    margin-right: ${pxToRem(8)};
    font-size: ${pxToRem(13)};
    font-weight: ${fontWeight.regular};
  }
  @media (max-width: ${pxToRem(leaderboardBreakpointW2)}) {
    min-width: ${pxToRem(40)};
    font-size: ${pxToRem(11)};
    margin-right: ${pxToRem(6)};
  }
`
