import { Field, Form, Formik } from "formik"
import * as React from "react"
import { EntryFinanceQuery, EntryFinanceQueryVariables } from "../../../../__generated__/EntryFinanceQuery"
import { UpsertEntryFinanceMutation, UpsertEntryFinanceMutationVariables } from "../../../../__generated__/UpsertEntryFinanceMutation"
import { extractValidationError } from "../../../../common/apiErrors"
import constants from "../../../../common/constants"
import { OpacityBtn } from "../../../components/Button"
import ErrorView from "../../../components/ErrorView"
import { errorClassFor, FormEnabledStatus, FormSuccessStatus } from "../../../components/Form"
import CheckmarkSvg from "../../../components/icons/Checkmark"
import CloseSvg from "../../../components/icons/Close"
import PencilSvg from "../../../components/icons/Pencil"
import LoadingView from "../../../components/LoadingView"
import Spinner from "../../../components/Spinner"
import { edgeToNode } from "../../../utils/misc-utils"
import { palette } from "../../../utils/style-utils"
import { ENTRY_FINANCE_QUERY, UPSERT_ENTRY_FINANCE_MUTATION } from "../../queries"
import Pagination from "../components/Pagination"
import { PoolPageSpacer, StandardPoolPageContainer } from "../components/PoolPage"
import { TableHeaderRow, TableRow, TruncateText } from "../components/StickyTable"
import { useMutation, useQuery } from "@apollo/client"
import { TPoolRouteProps } from "../../../../routes.d"
import useControls from "../../../hooks/useControls"
import { emptyObject, emptyArray } from "../../../../common/misc-utils"

import { OverflowScrollableX, TableCell, TableCellActions, TableCellEntry } from "../styles/PoolFinances.styles"
import Analytics from "../../../utils/analytics"

const fakeFinance = {} as never
const columns = [
  {
    label: "Entry Fee",
    value: "fee",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Won",
    value: "won",
  },
]

const PoolFinances = (props: TPoolRouteProps) => {
  const { poolData } = props
  const { entryId, detailedEntry } = poolData
  const { first, after, goToPage } = useControls(props)
  const entryRoles = (detailedEntry && detailedEntry.roles) || ([] as never[])
  const variables = { entryId, first, after }
  const skip = !variables.entryId
  const [upsertEntryFinanceMutation] = useMutation<UpsertEntryFinanceMutation, UpsertEntryFinanceMutationVariables>(UPSERT_ENTRY_FINANCE_MUTATION)
  const entryFinanceQuery = useQuery<EntryFinanceQuery, EntryFinanceQueryVariables>(ENTRY_FINANCE_QUERY, {
    variables,
    skip,
  })
  const isLoading = !entryFinanceQuery || entryFinanceQuery.networkStatus < 3
  const pageInfo = entryFinanceQuery.data?.entry.pool.entries.pageInfo || emptyObject
  const entries = ((!isLoading && entryFinanceQuery.data?.entry.pool.entries.edges) || emptyArray).map(edgeToNode)
  const currentIsManager = entryRoles.indexOf(constants.POOL_ROLES.MANAGER) > -1
  return (
    <StandardPoolPageContainer {...props} analyticsFeature="league management" analyticsSubfeature={`finances`} analyticsTitle={`Players - Finances`}>
      <PoolPageSpacer />
      <OverflowScrollableX>
        <TableHeaderRow>
          <TableCellEntry className="header">Team</TableCellEntry>
          {columns.map((column) => (
            <TableCell key={column.value} className="header">
              {column.label}
            </TableCell>
          ))}
          {currentIsManager ? <TableCellActions className="header">Edit</TableCellActions> : null}
        </TableHeaderRow>
        {entries.map((entry) => {
          const finance = entry.finance || fakeFinance
          const initialValues = {
            fee: finance.fee || "",
            won: finance.won || "",
            paid: finance.paid || "",
            entryId: entry.id,
          }
          return (
            <Formik
              key={entry.id}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                const submitVariables = Object.assign({}, values) as UpsertEntryFinanceMutationVariables
                columns.forEach(({ value }) => (submitVariables[value] = submitVariables[value] || 0))
                // console.log(`sumitting:`,variables)
                upsertEntryFinanceMutation({ variables: submitVariables })
                  .then((_res) => {
                    // console.log("res");
                    // console.dir(_res);
                    actions.setStatus(FormSuccessStatus)
                    actions.setSubmitting(false)
                    setTimeout(() => actions.resetForm({ values }), 900)
                  })
                  .catch((err) => {
                    // console.log('err')
                    // console.dir(err)
                    const apiErrors = extractValidationError(err)
                    actions.setErrors(apiErrors.errors)
                    actions.setSubmitting(false)
                  })
              }}
            >
              {(formikBag) => {
                const { isSubmitting, isValid, status } = formikBag
                const isEditing = !!status
                // console.dir(formikBag)
                const className = `is-mine__${entry.id === entryId} is-editing__${isEditing} `
                // console.log(`className: ${className} (${status})`)
                return (
                  <TableRow as={Form} className="overflow-hidden">
                    <TableCellEntry className={className}>
                      <TruncateText>{entry.name}</TruncateText>
                    </TableCellEntry>
                    {columns.map((column) => {
                      return (
                        <TableCell className={className + errorClassFor(formikBag, column.value)} key={column.value} as="label">
                          <span>
                            <span>$</span>
                            <Field
                              className={className + errorClassFor(formikBag, column.value)}
                              name={column.value}
                              as="input"
                              type="number"
                              min="0.00"
                              max="999999"
                              step="0.01"
                              placeholder={`0.00`}
                            />
                          </span>
                        </TableCell>
                      )
                    })}
                    {currentIsManager ? (
                      <TableCellActions className={className}>
                        <OpacityBtn
                          type="button"
                          as="button"
                          disabled={isSubmitting || status === FormSuccessStatus}
                          onClick={() => {
                            Analytics.trackAction("player finances edit", "finances", "player_finances_edit")
                            if (status === FormEnabledStatus) {
                              // formikBag.setStatus(null)
                              formikBag.resetForm()
                            } else {
                              formikBag.setStatus(FormEnabledStatus)
                            }
                          }}
                        >
                          {!isEditing ? <PencilSvg color={palette.uiBlue1} /> : <CloseSvg color="red" />}
                        </OpacityBtn>
                        {isEditing && (
                          <OpacityBtn type="submit" as="button" disabled={!isValid || isSubmitting || status === FormSuccessStatus}>
                            {isSubmitting ? (
                              <Spinner color="rgba(0,0,0,0.6)" />
                            ) : (
                              <CheckmarkSvg color={isValid ? palette.green : palette.uiSubText1} bgColor="rgba(0,0,0,0)" />
                            )}
                          </OpacityBtn>
                        )}
                      </TableCellActions>
                    ) : null}
                  </TableRow>
                )
              }}
            </Formik>
          )
        })}
        {entryFinanceQuery.error && <ErrorView error={entryFinanceQuery.error} refetch={entryFinanceQuery.refetch} />}
        {isLoading && <LoadingView />}
      </OverflowScrollableX>
      <Pagination {...pageInfo} hasPreviousPage={!!after} goToPage={goToPage} isLoading={isLoading} />
    </StandardPoolPageContainer>
  )
}

export default PoolFinances
