import * as React from "react"
import styled from "styled-components/macro"
import CarrotSvg from "../../../components/icons/Carrot"
import { pxToRem } from "../../../utils/style-utils"

const PaginationContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Spacer = styled.div`
  flex: 1 1 100%;
`

const PaginationBtn = styled.button`
  padding: ${pxToRem(6)} ${pxToRem(4)};
  display: flex;
  flex-flow: row nowrap;
  transition: all 0.2s ease-out;
  color: #000;
  padding: ${pxToRem(16)} ${pxToRem(8)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  & svg {
    width: ${pxToRem(22)};
    height: ${pxToRem(22)};
  }
  &:first-child svg {
    transform: rotate(180deg);
  }
  &[disabled] {
    visibility: hidden;
  }
  @media (hover: hover) {
    &:hover:not(:active) {
      opacity: 0.8;
    }
  }
  &:active {
    opacity: 0.7;
  }
`

export type IRelayConnectionCursor = string
export interface IRelayPageInfo {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor?: IRelayConnectionCursor | null
  endCursor?: IRelayConnectionCursor | null
  goToPage: (cursor: any) => void
  isLoading?: boolean
}

const Pagination = ({ hasPreviousPage, hasNextPage, endCursor, goToPage, isLoading }: IRelayPageInfo) => {
  return (
    <PaginationContainer>
      <PaginationBtn type="button" disabled={isLoading || !hasPreviousPage} onClick={() => goToPage(null)}>
        <CarrotSvg />
        <span>First</span>
      </PaginationBtn>
      <Spacer />
      <PaginationBtn type="button" disabled={isLoading || !hasNextPage} onClick={() => goToPage(endCursor)}>
        <span>View More</span>
        <CarrotSvg />
      </PaginationBtn>
    </PaginationContainer>
  )
}
export default Pagination
