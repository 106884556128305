import styled from "styled-components/macro"
import { fontWeight, palette, pxToRem } from "../../../utils/style-utils"

export const TableCell = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  flex: 0 1 auto;
  max-width: ${pxToRem(200)};
  min-width: ${pxToRem(200)};
  padding: ${pxToRem(6)} ${pxToRem(12)};

  align-items: center;
  white-space: nowrap;
  text-overflow: elipsis;
  &.is-editing__false {
    pointer-events: none;
    cursor: default;
  }

  &.is-editing__true {
    color: #000;
  }
  & * {
    transition: all 0.2s ease-out;
  }
  & > span {
    border-bottom: 1px solid transparent;
    padding: ${pxToRem(6)} 0;
  }
  &.is-editing__true:not(:first-child) > span {
    border-color: rgba(0, 0, 0, 0.7);
  }
  &.is-mine__true:first-child {
    border-left: 0.25rem solid ${palette.uiNavBlue1};
  }
  & input {
    background: transparent;
    & ::placeholder {
      color: ${palette.black};
    }
  }
  @media (max-width: ${pxToRem(900)}) {
    max-width: ${pxToRem(120)};
    min-width: ${pxToRem(120)};
  }
  @media (max-width: ${pxToRem(600)}) {
    max-width: ${pxToRem(75)};
    min-width: ${pxToRem(75)};
  }
`
export const TableCellEntry = styled(TableCell)`
  flex: 1 1 100%;
  min-width: ${pxToRem(150)} !important;
  max-width: unset !important;
  font-weight: ${fontWeight.semiBold};
`
export const TableCellActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 0 ${pxToRem(100)};
  min-width: ${pxToRem(100)};
  & button {
    width: 3em;
    height: 3em;
  }
  & .spinner {
    padding: 0.6em;
  }
  & .pencil {
    padding: 0.6em;
  }
`
export const OverflowScrollableX = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  & > * {
    width: ${pxToRem(520)};
  }
`
